<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{$t('title.dealerToDealerLoads')}}
                                        <span v-if="pagination.total">({{pagination.total}})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hovers>
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="dd-table" v-show="!operation">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(id)="{ item, index}">
                            {{ (pagination.perPage * (pagination.current-1)) + (index + 1) }}
                        </template>
                        <template v-slot:cell(supplier_carrier_id)="record">
                            {{record.item.supplier_carrier ? record.item.supplier_carrier.name : ''}}
                        </template>
                        <template v-slot:cell(client_id)="record">
                            {{record.item.client ? record.item.client.company_name : ''}}
                        </template>s
                        <template v-slot:cell(to_dealer_id)="record">
                            {{record.item.to_dealer ? record.item.to_dealer.name : ''}}
                        </template>
                        <template v-slot:cell(from_location_id)="{item}">
                            <span v-if="item.dealer_to_dealer_load_orders[0] && item.dealer_to_dealer_load_orders[0].from_location">
                                <print-ad :item="item.dealer_to_dealer_load_orders[0] && item.dealer_to_dealer_load_orders[0].from_location" titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(to_location_id)="{item}">
                            <span v-if="item.dealer_to_dealer_load_orders[0] && item.dealer_to_dealer_load_orders[0].to_location">
                                <print-ad :item="item.dealer_to_dealer_load_orders[0] && item.dealer_to_dealer_load_orders[0].to_location" titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(transport_vehicle_id)="record">
                            <span v-if="record.item.transport_vehicle">
                                <span v-if="record.item.transport_vehicle.truck_plate_number"> {{ record.item.transport_vehicle.truck_plate_number }} </span>
                                <span v-if="record.item.transport_vehicle.trailer_plate_number"> | {{ record.item.transport_vehicle.trailer_plate_number }} </span>
                            </span>
                        </template>
                        <template v-slot:cell(driver_id)="record">
                            {{record.item.driver ? record.item.driver.name : ''}}
                        </template>
                        <template v-slot:cell(loading_date)="record">
                            <span v-if="record.item.estimated_loading_time">
                                {{$global.utcDateToLocalDate(record.item.estimated_loading_time) }}
                            </span>
                        </template>
                        <template v-slot:cell(updated_at)="record">
                            {{$global.utcDateToLocalDate(record.item.updated_at) }}
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('detail', record.item.id)"
                               :title="$t('button.title.detailItem')" class=" ml-1"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.dd-table-->

                <div class="dd-operation">
                </div><!--/.dd-operation-->

                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->

            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>
    </div>
</template>
<script>
    import ListingMixin from '../../../../util/ListingMixin'
    import Error from '../../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'
    import qs from "qs"

    const FORM_STATE = {

    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
        },
        {
            label: self.$t('column.supplierCarrier'),
            key: 'supplier_carrier_id',
            sortable: true,
        },
        {
            label: self.$t('column.dealer'),
            key: 'to_dealer_id',
            sortable: true,
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: false,
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'to_location_id',
            sortable: false,
        },
        {
            label: self.$t('column.driver'),
            key: 'driver_id',
            sortable: true,
        },
        {
            label: self.$t('column.transportVehicle'),
            key: 'transport_vehicle_id',
            sortable: true,
        },
        {
            label: self.$t('column.qty'),
            key: 'qty',
            sortable: true,
        },
        {
            label: self.$t('column.loadingDate'),
            key: 'loading_date',
            sortable: false,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        {
            label: self.$t('column.action'),
            class: 'text-right w-100px',
            key: 'action',
        },
    ];

    export default {
        mixins: [ListingMixin],
        components: {
            Treeselect,
            Detail
        },
        data() {
            return {
                operationTitle: 'title.dealerToDealer',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: '/archives/dealer/to/dealer/loads',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    supplierCarriers: [],
                    locations: [],
                    dealers: [],
                    orders: [],
                    countries: [],
                    currencies: []
                },
                show: true,
            }
        },
        mounted() {
            this.getLocations()
            this.getSupplierCarriers()
            this.getCurrencies()
            this.getDealers()

            if (this.$route.query && this.$route.query.operation === 'edit' && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('title.dealerToDealer')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
            },
            async handleEditClick(id) {
            },
            async handleDeleteClick(id) {
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data
                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = data

                } catch (e) {
                    this.dropdowns.dealers = []
                }
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data

                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('archiveownddloadsview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80{
        width: 80px;
    }

    .w-220{
        width: 220px;
    }

    .in-center{
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    .order-select-chbx{
        position: absolute;
        right: -7px;
        top: -7px;
    }

    .add-load-btn{
        position: absolute;
        top: 12px;
        right: 50px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    .min-height-375{
        min-height: 375px;
    }

    .w-100px{
        width: 100px;
    }
</style>
